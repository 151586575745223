/*------    Ombre -------*/
import shadow from "../img/players/shadow.png";

/*------    Mathis Dossou-Yovo  -------*/
import doussouProfile from "../img/players/dossou/mathis-removebg-preview.png";
import doussouMP from "../img/players/dossou/Mathis_Dossou-Yovo.webp";

/*------   Malvin Ajinca    -------*/
import ajincaProfile from "../img/players/ajinca/melvin-removebg-preview.png";
import ajincaMP from "../img/players/ajinca/Melvin_Ajinca.webp";

/*------   Lucas Boucaud    -------*/
import boucaudProfile from "../img/players/boucaud/boucaud-removebg-preview.png";
import boucaudMP from "../img/players/boucaud/Lucas_Boucaud.webp";

/*------   William Pfister    -------*/
import williamProfile from "../img/players/pfister/william-removebg-preview.png";
import pfisterMP from "../img/players/pfister/William_Pfister.webp";

/*------   Loic Schwartz    --------*/
import schwartzProfil from "../img/players/schwartz/schwarz-removebg-preview.png"
import schwartzMP from "../img/players/schwartz/Loic_Schwartz.webp";

/*------ Dominik  ---------*/
import dominikProfile from '../img/players/dominik/dominik-removebg-preview.png';

/*-----  James Walker  ------*/
import walkerProfil from '../img/players/walker/walker-removebg-preview.png';

/*-----  Nate Johnson  ------*/
import johnsonProfil from '../img/players/johnson/johnson-removebg-preview.png';


/*----   Will Rayman   -----*/
import raymanProfil from '../img/players/rayman/rayman-removebg-preview.png';

export const playersData = [
    {
        id: 1,
        firstName: "Mathis",
        lastName: "Dossou-Yovo",
        position: "Pivot",
        number: "12",
        age: 22,
        birth: "6 novembre 2000",
        birthPlace: "Chateauroux, Indre",
        size: "2.05",
        teamPresence: "2022",
        country: "FRANCE",
        profilImg: doussouProfile,
        mainPageImg: doussouMP,
        smallResume: "Mathis Dossou Yovo est un joueur de basketball né le 06 novembre 2000. Il mesure 2m04. Il joue au poste de pivot.",
        resume: "Passé par le pôle Espoir de la région Centre à Orléans et Champion de France U15 avec Fleury-les-Aubrais, Champion de France des ligues avec la ligue du centre et Champion de France UNSS, il évolue ensuite trois saisons avec l'INSEP ou il signe pour sa dernière saison 8 points par matchs et 7,3 rebonds1. Il y gagnera également l'Euroleague Junior à Istanbul en 2017, et sera élu dans le cinq majeur de l'Euroleague junior à Kaunas (Žalgirio Arena) en 2018. Il participa au championnat d'Europe U16 à Radom en 2016 (5ème place), au championnat d'Europe U18 avec une année d'avance à Bratislava en 2017 (6e place) et sera médaillé de bronze au championnat d'Europe avec l'équipe de France U18 à Riga en 2018 (dont il était capitaine) ou il finit meilleur marqueur (16,4 points par match) et rebondeur (8,1) de cette équipe1 pour un total de 18,6 d'évaluation.",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin BasketBall",
                    comeIn: "2022",
                    ending: "-",
                    leagueName: "ProB & Betclic Élite"
                },
                {
                    club: "CHALON-SUR-SAÔNE",
                    comeIn: "2021",
                    ending: "2022",
                    leagueName: "Ligue National de basketball. Pro B"
                },
                {
                    club: "ADA BLOIS",
                    comeIn: "2020",
                    ending: "2021",
                    leagueName: "Ligue National de basketball. Pro B"
                },
                {
                    club: "ALM EVREUX BASKET",
                    comeIn: "2020",
                    ending: "2021",
                    leagueName: "Ligue National de basketball. Pro B"
                },
                {
                    club: "CHALON-SUR-SAÔNE",
                    comeIn: "2020",
                    ending: "2021",
                    leagueName: "Ligue National de basketball. Pro B"
                },
            ],
            amateur: [
                {
                    club: "Élan Sportif Chalon-Sur-Saone U21",
                    comeIn: "2018",
                    ending: "2019",
                    leagueName: ""
                },
                {
                    club: "Centre Federal du Basket-Ball (NM1)",
                    comeIn: "2016",
                    ending: "2018",
                    leagueName: ""
                },
                {
                    club: "Centre Federal du Basket-Ball (U18)",
                    comeIn: "2015",
                    ending: "2016",
                    leagueName: ""
                },
                {
                    club: "2014-2015 CFL Les Aubrais (U17)",
                    comeIn: "2014",
                    ending: "2015",
                    leagueName: ""
                },
                {
                    club: "LA BÉRRICHONNE BASKET",
                    comeIn: "2008",
                    ending: "2014",
                    leagueName: ""
                }
            ],
            palmares: [
                {
                    championchipTitle: "MÉDAILLE DE BRONZE",
                    year: "2018",
                    championship: "Championnat d'Europe de basket-ball des moins de 18",
                    place: "Lettonie",
                },
                {
                    championchipTitle: "MÉDAILLE DE BRONZE",
                    year: "2019",
                    championship: "Championnat du monde U19",
                    place: "Grèce",
                },
                {
                    championchipTitle: "Champion",
                    year: "2023",
                    championship: "Championnat de Pro B",
                    place: "Saint-Quentin",
                },
            ],

        },
        pictures: [
            {
                playerId: [1],
                title: "France select team",
                tag: ["équipe de France"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo Sélectionné en équipe de france",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
            {
                playerId: [1],
                title: "France select team",
                tag: ["Champions"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo avec le coach",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    {
        id: 3,
        firstName: "Melvin",
        lastName: "Ajinça",
        position: "Ailier",
        number: "8",
        age: 19,
        birth: "26 juin 2004",
        birthPlace: "Villeneuve-Saint-Georges, Val-de-Marne",
        size: "2,03",
        teamPresence: "2022",
        country: "FRANCE",
        profilImg: ajincaProfile,
        mainPageImg: ajincaMP,
        smallResume: "Melvin Pierre Ajinça, né le 26 juin 2004 à Villeneuve-Saint-Georges dans le Val-de-Marne, est un joueur français de basket-ball évoluant au poste d'ailier.",
        resume: "Melvin Ajinça est le cousin d'Alexis Ajinça, ancien joueur de l'équipe de France de basket-ball1.\n" +
            "\n" +
            "Après avoir joué pendant 3 années au centre fédéral de basket-ball, il décide, en juillet 2022, de débuter sa carrière professionnelle avec le club de Saint-Quentin en Pro B2.",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin Basket-Ball",
                    comeIn: "2022",
                    ending: "-",
                    leagueName: "ProB & Betclic Élite"
                }
            ],
            amateur: [
                {
                    club: "2019-2022 Centre Federal du Basket-Ball ",
                    comeIn: "2019",
                    ending: "2022",
                    leagueName: "NM1"
                },
                {
                    club: "US Ris-Orangis",
                    comeIn: "2015",
                    ending: "2018",
                    leagueName: ""
                },
                {
                    club: "ES Montgeron Basket",
                    comeIn: "2012",
                    ending: "2015",
                    leagueName: ""
                },
            ],
            palmares: [
                {
                    championchipTitle: "Champion",
                    year: "2023",
                    championship: "Championnat de Pro B",
                    place: "Saint-Quentin",
                },
                {
                    championchipTitle: "Meilleur Jeune de Pro B",
                    year: "2023",
                    championship: "Championnat de Pro B",
                    place: "Saint-Quentin",
                },
                {
                    championchipTitle: "Médaille d'argent",
                    year: "2023",
                    championship: "Coupe du monde des moins de 19 ans",
                    place: "Hongrie",
                },
            ],

        },
        pictures: [
            {
                playerId: [1],
                title: "France select team",
                tag: ["équipe de France"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo Sélectionné en équipe de france",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
            {
                playerId: [1],
                title: "France select team",
                tag: ["Champions"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo avec le coach",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    {
        id: 4,
        firstName: "Lucas",
        lastName: "Boucaud",
        position: "Meneur",
        number: "10",
        age: 22,
        birth: "le 30 août 2000",
        birthPlace: "-",
        size: "1,88",
        teamPresence: "2021",
        country: "FRANCE",
        profilImg: boucaudProfile,
        mainPageImg: boucaudMP,
        smallResume: "-",
        resume: "-",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin BasketBall ",
                    comeIn: "2021",
                    ending: "-",
                    leagueName: "ProB & Betclic Elite"
                },
                {
                    club: "Etoile Angers Basket",
                    comeIn: "2020",
                    ending: "2021",
                    leagueName: "NM1"
                },
                {
                    club: " Vendee Challans Basket",
                    comeIn: "2018",
                    ending: "2020",
                    leagueName: "NM3 & NM1"
                }
            ],
            amateur: [
                {
                    club: "Centre Fédéral",
                    comeIn: "2018",
                    ending: "2022",
                    leagueName: ""
                },
                {
                    club: "US Ris-Orangis",
                    comeIn: "2015",
                    ending: "2018",
                    leagueName: ""
                },
                {
                    club: "ES Montgeron Basket",
                    comeIn: "2012",
                    ending: "2015",
                    leagueName: ""
                },
            ],
            palmares: [
                {
                    championchipTitle: "Champion",
                    year: "2023",
                    championship: "Championnat de Pro B",
                    place: "Saint-Quentin",
                },
            ],

        },
        pictures: [
            {
                playerId: [1],
                title: "France select team",
                tag: ["équipe de France"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo Sélectionné en équipe de france",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
            {
                playerId: [1],
                title: "France select team",
                tag: ["Champions"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo avec le coach",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    {
        id: 5,
        firstName: "William ",
        lastName: "Pfister",
        position: "Ailier Fort",
        number: "5",
        age: 28,
        birth: "le 5 janvier 1995",
        birthPlace: "-",
        size: "2,02",
        teamPresence: "2020",
        country: "FRANCE",
        profilImg: williamProfile,
        mainPageImg: pfisterMP,
        smallResume: "William Pfister, né le 5 janvier 1995, est un joueur professionnel français de basket-ball. Il mesure 2,02 m",
        resume: "À la fin de la saison 2019-2020 passée au Caen Basket Calvados (NM1), William Pfister fait valoir sa clause lui permettant de quitter Caen en cas de non-montée en Pro B. Il signe au Saint-Quentin Basket-Ball en Pro B pour la saison 2020-20212.\n" +
            "Le 4 juin 2022, William Pfister signe pour une 3e saison avec Saint-Quentin Basket-Ball",
        carrier: {
            pro: [

                {
                    club: "Saint-Quentin Basket-Ball",
                    comeIn: "2020",
                    ending: "-",
                    leagueName: "ProB & Betclic Élite"
                },
                {
                    club: "Caen Basket Calvados ",
                    comeIn: "2019",
                    ending: "2020",
                    leagueName: "NM1"
                },
                {
                    club: "Golbey Epinal Thaon Vosges",
                    comeIn: "2018",
                    ending: "2019",
                    leagueName: "NM1"
                },
                {
                    club: "Florida Atlantic University",
                    comeIn: "2016",
                    ending: "2018",
                    leagueName: "NCAA"
                },
                {
                    club: "Northwest Florida State College",
                    comeIn: "2014",
                    ending: "2016",
                    leagueName: "JUCO"
                },
                {
                    club: "BC Montbrison",
                    comeIn: "2013",
                    ending: "2014",
                    leagueName: "NM2"
                }, {
                    club: "JL Bourg",
                    comeIn: "2012",
                    ending: "2013",
                    leagueName: "PRO B"
                },
            ],
            amateur: [
                {
                    club: "JL Bourg",
                    comeIn: "2011",
                    ending: "2012",
                    leagueName: "U21"
                },
            ],
            palmares: [
                {
                    championchipTitle: "Champion",
                    year: "2023",
                    championship: "Championnat de Pro B",
                    place: "Saint-Quentin",
                },
            ],

        },
        pictures: [
            {
                playerId: [1],
                title: "France select team",
                tag: ["équipe de France"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo Sélectionné en équipe de france",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
            {
                playerId: [1],
                title: "France select team",
                tag: ["Champions"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo avec le coach",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    {
        id: 6,
        firstName: "Loïc",
        lastName: "Schwartz",
        position: "Arrière",
        number: "0",
        age: 30,
        birth: "le 4 décembre 1992",
        birthPlace: "-",
        size: "1,97",
        teamPresence: "2022",
        country: "BELGIQUE",
        profilImg: schwartzProfil,
        mainPageImg: schwartzMP,
        smallResume: "Loïc Schwartz, né le 4 décembre 1992, est un joueur professionnel international belge de basket-ball. Il mesure 1,98 m.",
        resume: "Il commence sa carrière professionnelle en Belgique en 2014 avec l'équipe de Spirou Charleroi Basket Club. À la fin de la saison 2017, il rejoint l'équipe du Filou Oostende où il évolue pendant 4 saisons.\n" +
            "\n" +
            "En 2021, il signe avec l'équipe grecque de Promithéas Patras qu'il quitte en cours de saison pour rejoindre Orléans Loiret Basket.\n" +
            "\n" +
            "Il arrive à Saint-Quentin en octobre 2022 en tant que pigiste2.\n" +
            "\n" +
            "En février 2023, il est sélectionné pour disputer avec l'équipe de Belgique les deux derniers matchs des éliminatoires de Coupe du monde 20233.",
        carrier: {
            pro: [

                {
                    club: "Saint-Quentin Basket-Ball",
                    comeIn: "2022",
                    ending: "-",
                    leagueName: "ProB & Betclic Elite"
                },
                {
                    club: "Orléans Loiret Basket",
                    comeIn: "2022",
                    ending: "2022",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "ASP Promithéas Patras",
                    comeIn: "2021",
                    ending: "2022",
                    leagueName: "D1 Grèce & Eurocup"
                },
                {
                    club: "Filou Oostende",
                    comeIn: "2017",
                    ending: "2021",
                    leagueName: "D1 Belgique, FIBA Europe Cup & Basketball Champions League"
                },
                {
                    club: "Spirou Charleroi Basket Club",
                    comeIn: "2014",
                    ending: "2017",
                    leagueName: "D1 Belgique, FIBA Europe Cup & Basketball Champions League"
                }, {
                    club: "VOO Verviers-Pepinster",
                    comeIn: "2013",
                    ending: "2014",
                    leagueName: "D1 Belgique"
                },
                {
                    club: "Dexia Mons-Hainaut",
                    comeIn: "2010",
                    ending: "2013",
                    leagueName: "D1 & D2 Belgique"
                },
            ],
            amateur: [
                {
                    club: "",
                    comeIn: "",
                    ending: "",
                    leagueName: ""
                },
            ],
            palmares: [
                {
                    championchipTitle: "Champion",
                    year: "2023",
                    championship: "Championnat de Pro B",
                    place: "Saint-Quentin",
                },
            ],

        },
        pictures: [
            {
                playerId: [1],
                title: "France select team",
                tag: ["équipe de France"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo Sélectionné en équipe de france",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
            {
                playerId: [1],
                title: "France select team",
                tag: ["Champions"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo avec le coach",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    {
        id: 7,
        firstName: "Dominik",
        lastName: "Olejniczak",
        position: "Pivot",
        number: "13",
        age: 27,
        birth: "1er juillet 1996",
        birthPlace: "Toruń (Pologne)",
        size: "2.13",
        teamPresence: "2023",
        country: "POLOGNE",
        profilImg: dominikProfile,
        mainPageImg: dominikProfile,
        smallResume: "Dominik Olejniczak, né le 1er juillet 1996 à Toruń en Couïavie-Poméranie, est un joueur polonais de basket-ball. Il évolue au poste de pivot.",
        resume: "Entre 2012 et 2015, il joue au SMS PZKosz Wladyslawowo en ligue polonaise 2.\n" +
            "\n" +
            "En 2015, il part aux États-Unis pour suivre un cursus universitaire américain et intégrer une équipe NCAA. Il intègre l'Université Drake en Iowa et joue pour l'équipe de basket-ball des Bulldogs de Drake. En 30 matches, il a des moyennes de 6,5 points et 4,3 rebonds par match. Avec une efficacité aux tirs à deux points à 72,2%, il est le deuxième meilleur joueur le plus adroit de toute la division I de NCAA au cours de la saison 2015-2016.\n" +
            "\n" +
            "Après cette première saison américaine, il décide de s'installer à l'Université du Mississippi où il s'entraîne avec les Rebels d'Ole Miss et joue en championnat à partir de la saison 2017-2018.\n" +
            "\n" +
            "Le 6 juillet 2021, il signe un contrat de deux ans avec le BCM Gravelines-Dunkerque1.\n" +
            "\n" +
            "Le 24 juin 2023, il rejoint le promu Saint-Quentin2.",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin Basket-Ball",
                    comeIn: "2023",
                    ending: "-",
                    leagueName: "ProB & Betclic Élite"
                },
                {
                    club: "BCM Gravelines-Dunkerque",
                    comeIn: "2021",
                    ending: "2023",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Trefl Sopot",
                    comeIn: "2020",
                    ending: "2021",
                    leagueName: "D1 Pologne"
                }
            ],
            amateur: [
                {
                    club: "Florida State University",
                    comeIn: "2019",
                    ending: "2020",
                    leagueName: "NCAA"
                },
                {
                    club: "The University of Mississippi",
                    comeIn: "2016",
                    ending: "2019",
                    leagueName: "NCAA"
                },
                {
                    club: "Drake University",
                    comeIn: "2015",
                    ending: "2016",
                    leagueName: "NCAA"
                },
                {
                    club: "SMS PZKosz Wladyslawowo",
                    comeIn: "2012",
                    ending: "2015",
                    leagueName: ""
                },
                {
                    club: "TKM Wloclawek",
                    comeIn: "2011",
                    ending: "2012",
                    leagueName: "U16"
                }
            ],
            palmares: [
            ],

        },
        pictures: [
            {
                playerId: [1],
                title: "France select team",
                tag: ["équipe de France"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo Sélectionné en équipe de france",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
            {
                playerId: [1],
                title: "France select team",
                tag: ["Champions"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo avec le coach",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    }, /*
    {
        id: 8,
        firstName: "James Michael",
        lastName: "Walker",
        position: "Ailier",
        number: "23",
        age: 25,
        birth: "28 mars 1998",
        birthPlace: "Atlanta, (USA)",
        size: "1.96",
        teamPresence: "2023",
        country: "USA",
        profilImg: walkerProfil,
        mainPageImg: walkerProfil,
        smallResume: "James Michael \"M. J.\" Walker Jr., né le 28 mars 1998 à Atlanta, en Géorgie, est un joueur américain de basket-ball évoluant au poste d'ailier.",
        resume: "Le 29 juillet 2021, automatiquement éligible à la draft 2021 de la NBA, il n'est pas sélectionné.\n" +
            "\n" +
            "Durant l'été 2021, il participe à la NBA Summer League de Las Vegas avec les Knicks de New York.\n" +
            "\n" +
            "Le 20 août 2021, il signe un contrat avec les Knicks de New York1. Le 16 octobre 2021, il est libéré de son contrat2.\n" +
            "\n" +
            "Le 25 octobre 2021, il rejoint l'équipe de G-League affiliée aux Knicks de New York, les Knicks de Westchester.\n" +
            "\n" +
            "Le 30 décembre 2021, il signe un contrat de dix jours avec les Suns de Phoenix3. Il joue deux matches avec les Suns.\n" +
            "\n" +
            "Le 9 janvier 2022, il retourne chez les Knicks de Westchester.\n" +
            "\n" +
            "Durant l'été 2022, il participe à la NBA Summer League de Las Vegas avec les Knicks de New York.\n" +
            "\n" +
            "Le 21 septembre 2022, il signe un contrat avec les Knicks de New York. Le 23 septembre, il est libéré de son contrat.\n" +
            "\n" +
            "Le 23 octobre 2022, il retourne chez les Knicks de Westchester. " +
            "\n" +
            "Le 4 août 2023, il signe avec l'équipe française du Saint-Quentin Basket-Ball",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin BasketBall",
                    comeIn: "2023",
                    ending: "-",
                    leagueName: "ProB & Betclic Elite"
                },
                {
                    club: "Knicks de Westchester",
                    comeIn: "2022",
                    ending: "2023",
                    leagueName: "NBA G League"
                },
                {
                    club: "Suns de Phoenix",
                    comeIn: "2021",
                    ending: "2022",
                    leagueName: "NBA"
                },
                {
                    club: "Knicks de Westchester",
                    comeIn: "2021",
                    ending: "2022",
                    leagueName: "NBA G League"
                }
            ],
            amateur: [
                {
                    club: "Seminoles de Florida State",
                    comeIn: "2017",
                    ending: "2021",
                    leagueName: ""
                }
            ],
            palmares: [
            ],

        },
        pictures: [
            {
                playerId: [1],
                title: "France select team",
                tag: ["équipe de France"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo Sélectionné en équipe de france",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
            {
                playerId: [1],
                title: "France select team",
                tag: ["Champions"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo avec le coach",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    }, */
    {
        id: 9,
        firstName: "Nate",
        lastName: "Johnson",
        position: "Arrière",
        number: "1",
        age: 25,
        birth: "25 avril 1998",
        birthPlace: "Hollywood, (USA)",
        size: "1.93",
        teamPresence: "2023",
        country: "USA",
        profilImg: johnsonProfil,
        mainPageImg: johnsonProfil,
        smallResume: "Nate Johnson, né le 25 avril 1998 à Hollywood, en Floride, est un joueur américain de basket-ball évoluant au poste d'arrière.",
        resume: "Entre 2016 et 2020, il joue pour les Runnin' Bulldogs de Gardner-Webb à l'université Gardner-Webb. Entre 2020 et 2022, il joue pour les Musketeers de Xavier à l'université Xavier. Le 23 juin 2022, automatiquement éligible à la draft 2022 de la NBA, il n'est pas sélectionné.\n" +
            "\n" +
            "Le 1er août 2022, il signe avec l'équipe roumaine du CSM ABC Athletic Constanța. Le 14 juillet 2023, il signe avec Saint-Quentin Basket-Ball.",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin BasketBall",
                    comeIn: "2023",
                    ending: "-",
                    leagueName: "ProB & Betclic Elite"
                },
                {
                    club: "Athletic Constanta",
                    comeIn: "2022",
                    ending: "2023",
                    leagueName: "Liga Națională, (Roumanie)"
                },
            ],
            amateur: [
                {
                    club: "Musketeers de Xavier",
                    comeIn: "2020",
                    ending: "2022",
                    leagueName: ""
                },
                {
                    club: "Runnin' Bulldogs de Gardner-Webb",
                    comeIn: "2016",
                    ending: "2020",
                    leagueName: ""
                }
            ],
            palmares: [
            ],

        },
        pictures: [
            {
                playerId: [1],
                title: "France select team",
                tag: ["équipe de France"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo Sélectionné en équipe de france",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
            {
                playerId: [1],
                title: "France select team",
                tag: ["Champions"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo avec le coach",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    {
        id: 11,
        firstName: "Will",
        lastName: "Rayman",
        position: "Ailier Fort",
        number: "11",
        age: 26,
        birth: "1er avril 1997",
        birthPlace: "New York, (USA)",
        size: "2.03",
        teamPresence: "2023",
        country: "USA",
        profilImg: raymanProfil,
        mainPageImg: raymanProfil,
        smallResume: "William Rayman est un joueur de basketball américain né le 01 avril 1997. Il mesure 2m03. Il joue au poste d'ailier.",
        resume: "William Henry Rayman est un basketteur américano-israélien qui joue le poste d'attaquant puissant pour le Maccabi Tel Aviv de la Premier League israélienne de basket-ball et de l' EuroLeague . Il a joué au basket-ball universitaire pour Colgate , pour qui, au cours de sa dernière année, il a été joueur défensif de l'année de la Patriot League et première équipe de la All-Patriot League." +
            "Rayman est né à New York de Graham Rayman (journaliste et auteur) et de Bronwen Hruska (romancier et directeur de Soho Press ), et a grandi dans l' Upper West Side de Manhattan . Il rejoint SQBB en 2023",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin BasketBall",
                    comeIn: "2023",
                    ending: "-",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Maccabi Tel-Aviv",
                    comeIn: "2023",
                    ending: "-",
                    leagueName: "Première Ligue Israéllienne"
                },
                {
                    club: "Hapoël Haïfa",
                    comeIn: "2021",
                    ending: "2023",
                    leagueName: "Première Ligue Israéllienne"
                },
                {
                    club: "BG Göttingen",
                    comeIn: "2020",
                    ending: "2021",
                    leagueName: "Basketball Bundesliga."
                },
            ],
            amateur: [
            ],
            palmares: [
            ],

        },
        pictures: [
            {
                playerId: [1],
                title: "France select team",
                tag: ["équipe de France"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo Sélectionné en équipe de france",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
            {
                playerId: [1],
                title: "France select team",
                tag: ["Champions"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo avec le coach",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },    {
        id: 12,
        firstName: "Yiftach",
        lastName: "Ziv",
        position: "Arrière / Meneur",
        number: "99",
        age: 28,
        birth: "9 juillet 1995",
        birthPlace: "Haifa, Israël",
        size: "1.91",
        teamPresence: "2023",
        country: "ISRAËL",
        profilImg: shadow,
        mainPageImg: shadow,
        smallResume: "Yiftach Ziv est un joueur de basketball né le 9 juillet 1995. Mesurant 1m91, Yiftach évolue au poste de arrière / meneur.",
        resume: "Habitué du basket israélien, Yiftach a connu le plus haut niveau européen. Sélectionné dès la fin de l’adolescence par son pays pour porter les couleurs aux côtés des sections U18 et U20, il découvre la première division locale lors de sa signature du côté du Maccabi Tel Aviv. Après avoir connu à la fois l’Euroleague et de nombreux clubs du pays, le combo-guard décide en 2023 de tenter une première expérience à l’étranger avec Granada chez nos voisins espagnols. Après 6 mois, Yiftach rompt son contrat et rallie le SQBB à l’aube de 2024. ",
        carrier: {
            pro:  [
                {
                        club: "Saint-Quentin Basket-Ball",
                        comeIn: "2023",
                        ending: "-",
                        leagueName: "Betclic Élite"
                    },
            {
                        club: "Corivan Granada",
                        comeIn: "2023",
                        ending: "2023",
                        leagueName: "Liga Endesa"
                    },
                    {
                        club: "Maccabi Tel Aviv",
                        comeIn: "2021",
                        ending: "2023",
                        leagueName: "Super League"
                    },
                    {
                        club: "Gilboa Galil",
                        comeIn: "2019",
                        ending: "2021",
                        leagueName: "Super League"
                    },
                    {
                        club: "Ironi Nahariya",
                        comeIn: "2017",
                        ending: "2019",
                        leagueName: "Super League"
                    },
                    {
                        club: "Maccabi Ashdod",
                        comeIn: "2016",
                        ending: "2017",
                        leagueName: "Super League"
                    },
                    {
                        club: "Bnei Herzeliya",
                        comeIn: "2015",
                        ending: "2016",
                        leagueName: "Israël - 2ème division"
                    },
                    {
                       club: "Maccabi Haifa",
                        comeIn: "2015",
                        ending: "2016",
                        leagueName: "Super League"
                    },
                    {
                        club: "Elitzur Ramla",
                        comeIn: "2014",
                        ending: "2015",
                        leagueName: "Israël - 2ème division"
                    },
                    {
                        club: "Maccabi Haifa",
                        comeIn: "2014",
                        ending: "2015",
                        leagueName: "Super League"
                    },
                    {
                        club: "Hapoel Kiryat Tivon",
                        comeIn: "2013",
                        ending: "2014",
                        leagueName: "Israël - 2ème division"
                    },
                    {
                        club: "Ironi Kiryat Ata",
                        comeIn: "2013",
                        ending: "2014",
                        leagueName: "Israël - 2ème division"
                    }
                ],
            amateur: [
            
            ],
            palmares: [
               
            ],

        },
        pictures: [
            {
                playerId: [1],
                title: "France select team",
                tag: ["équipe de France"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo Sélectionné en équipe de france",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
            {
                playerId: [1],
                title: "France select team",
                tag: ["Champions"],
                src: doussouProfile,
                legend: "Mathis Doussou-Yovo avec le coach",
                date: "",
                link: "",
                author: "",
                persons: [],
            },
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    }
]