import React from 'react';
import Shape from "../../../components/UI/Shap/Shape";
import './BecomePartners.css';
import {Link} from "react-router-dom";
import partnersImg from "../../../img/becomePartners/become_associer.png";
import partnerImg2 from "../../../img/becomePartners/partners2.png";
import partnerImg3 from "../../../img/becomePartners/partners3.png";
import SqbbPageTitle from "../../../components/UI/SqbbPageTitle/SqbbPageTitle";
import ratte7 from "../../../img/pierreRatte/pierre_rate4.jpg";
import SqbbShap from "../../../components/UI/SqbbShap/SqbbShap";

const BecomePartners = () => {
    return (
        <>
            <SqbbShap
                height={'50vh'}
                backgroundColor={`linear-gradient(rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.4) 45%, rgba(255, 255, 255, 1) 100%)`}
                backgroundImage={ratte7}
                backgroundAttachment={'fixed'}
                backgroundPosition={'center center'}
                backgroundRepeat={'no-repeat'}
                backgroundSize={'cover'}
            >
                <SqbbPageTitle title={"Devenez partenaire"} color={"#0c264b"}></SqbbPageTitle>
            </SqbbShap>

            <section id="experience" className="experience">
                <div className="container">

                    <div className="row">
                        <div className="col-xl-4 col-lg-5" data-aos="fade-up">
                            <div className="content">
                                <h3>VIVRE UNE EXPÉRIENCE UNIQUE</h3>
                                <p>
                                    En devenant partenaire du SQBB, vous aurez l'opportunité de vivre une expérience unique
                                    en vous impliquant dans le monde passionnant du basket professionnel.
                                </p>
                                <div className="text-center">
                                    <Link to={'/contact'} className="more_btn"> Contactez-nous</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-7 d-flex">
                            <div className="icon-boxes d-flex flex-column justify-content-center">
                                <div className="row">
                                    <div className="col-xl-4 d-flex align-items-stretch" data-aos="fade-up"
                                         data-aos-delay="100">
                                        <div className="icon-box mt-4 mt-xl-0">
                                            <h4>Partagez</h4>
                                            <p>Partagez entre collaborateurs des moments forts d’émotion en assistant à un spectacle intense ! </p>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 d-flex align-items-stretch" data-aos="fade-up"
                                         data-aos-delay="200">
                                        <div className="icon-box mt-4 mt-xl-0">
                                            <h4>Faites découvrir</h4>
                                            <p>Faites découvrir à vos clients et prospects l’ambiance exceptionnelle qu’il règne à Pierre-Ratte !</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 d-flex align-items-stretch" data-aos="fade-up"
                                         data-aos-delay="300">
                                        <div className="icon-box mt-4 mt-xl-0">
                                            <h4>Encouragez</h4>
                                            <p>Encouragez l’équipe de Saint-Quentin avec les 3500 autres spectateurs réunis pour que
                                                vous tous, ne fassiez plus qu’un : le 6ème homme !</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section id="cta" className="cta">
                <div className="container" data-aos="zoom-out">
                    <div className="row g-5">
                        <div
                            className="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
                            <h3>S’ASSOCIER À L’IMAGE DU CLUB</h3>
                            <p>
                                En collaborant avec le SQBB, vous aurez l'opportunité de vous associer à l'image d'un club
                                évoluant dans l’Élite du basket français de première division et d’ainsi renforcer votre image
                                de marque.
                            </p>
                            <p>
                                En s’associant au club de Saint-Quentin, qui bénéfiie d'une base de fans
                                passionnés et fidèles, vous profiterez d'une visibilité accrue tant au niveau local que
                                national.
                            </p>
                        </div>
                        <div className="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
                            <div className={"img"}>
                                <img src={ partnersImg } alt="supportaires de sqbb" className="img-fluid"/>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section id="diagonal" className="diagonal section-bg">
                <div className="container" data-aos="fade-up">

                    <div className="row no-gutters">
                        <div className="content col-xl-5 d-flex align-items-stretch">
                            <div className="content">
                                <h3>SOUTENIR UN PROJET AMBITIEUX</h3>
                                <p>
                                    En soutenant le SQBB, vous rejoignez un projet ambitieux et en plein essor. Notre récent
                                    titre de Champion de France de Pro B (2ème division) témoigne de notre engagement à
                                    atteindre de nouveaux sommets dans le basket français.
                                </p>
                                <a href="#" className="about-btn"><span>About us</span> <i
                                    className="bx bx-chevron-right"></i></a>
                            </div>
                        </div>
                        <div className="col-xl-7 d-flex align-items-stretch">
                            <div className="icon-boxes d-flex flex-column justify-content-center">
                                <div className="row">
                                    <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                                        <h4>Rejoignez-nous</h4>
                                        <p>Faites partie de notre ascension
                                            dans la meilleure ligue du basket français en devenant partenaire du club.</p>
                                    </div>
                                    <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                                        <i className="bx bx-cube-alt"></i>
                                        <h4>Contribuez à notre développement</h4>
                                        <p>Votre soutien
                                            contribuera directement à notre développement, nous permettant de continuer à progresser
                                            et à représenter fièrement notre ville et notre région.</p>
                                    </div>
                                    <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                                        <i className="bx bx-images"></i>
                                        <h4>Soutenez le sport local</h4>
                                        <p>En associant votre entreprise à notre
                                            projet ambitieux, vous montrerez votre volonté de soutenir le sport local, de valoriser le
                                            talent et de participer activement à la réussite d'une équipe en pleine croissance.</p>
                                    </div>
                                    <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                                        <i className="bx bx-shield"></i>
                                        <h4>Participer à notre réussite</h4>
                                        <p>Participer activement à la réussite d'une équipe en pleine croissance.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section id="cta2" className="cta">
                <div className="container" data-aos="zoom-out">
                    <div className="row g-5">

                        <div className="col-lg-4 order-last order-md-first">
                            <div className="about-img">
                                <img src={ partnerImg2 } className="img-fluid" alt="spectacle sqbb"/>
                            </div>
                        </div>

                        <div
                            className="col-lg-8 col-md-6 content d-flex flex-column justify-content-center ">
                            <h3>DES <em>PRESTATIONS</em> ADAPTÉES À VOS BESOINS</h3>
                            <p>
                                En tant que partenaire du SQBB, nous vous offrons des prestations sur mesure, adaptées à
                                vos besoins spécifiques en termes d’hospitalités, d’événementiel, de visibilité, de marketing
                                et de relations publiques.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="diagonal" className="diagonal section-bg">
                <div className="container" data-aos="fade-up">

                    <div className="row no-gutters">
                        <div className="content col-xl-5 d-flex align-items-stretch">
                            <div className="content">
                                <h3>FORMULES VIP</h3>
                                <p>
                                    Profitez des espaces VIP réservés et des rencontres avec d'autres partenaires prestigieux
                                    pour partager avec vos relations professionnelles un spectacle riche en émotions !
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-7 d-flex align-items-stretch">
                            <div className="icon-boxes d-flex flex-column justify-content-center">
                                <div className="row">
                                    <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                                        <h4>Fidélisez vos clients</h4>
                                        <p>
                                            Nos prestations VIP fidélisent vos clients et récompensent votre personnel en vous mettant en
                                            contact avec les autres entreprises partenaires du club dans une ambiance conviviale.
                                        </p>
                                    </div>
                                    <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                                        <i className="bx bx-images"></i>
                                        <h4>Une offre adaptée</h4>
                                        <p>
                                            Nous vous proposons une offre adaptée à vos besoins afin de maintenir
                                            et renforcer notre relation professionnelle.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section id="cta" className="cta">
                <div className="container" data-aos="zoom-out">
                    <div className="row g-5">
                        <div
                            className="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
                            <h3><em>SUPPORTS</em> DE COMMUNICATION</h3>
                            <p>
                                Grâce à nos différents supports de communication, votre notoriété et votre image sont liées
                                à l'image du SQBB et de ses valeurs, tant auprès des spectateurs de Pierre-Ratte que de la
                                communauté digitale.
                            </p>
                            <p>
                                Bénéficiez ainsi d'une présence promotionnelle et collaborez avec
                                notre équipe dédiée pour développer des initiatives conjointes et des campagnes
                                publicitaires ciblées, vous permettant d'atteindre votre public cible de manière efficace.
                            </p>
                        </div>
                        <div className="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
                            <div className="img">
                                <img src={ partnerImg3 } alt="" className="img-fluid"/>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

        </>
    );
};

export default BecomePartners;