import React from 'react';
import leadersCup from "../../../../img/competitions/leaderscup_nondate.png";
import classes from "./LeadersCup.module.css";
import SqbbPageTitle from "../../../../components/UI/SqbbPageTitle/SqbbPageTitle";
import ratte7 from "../../../../img/pierreRatte/pierre_rate4.jpg";
import SqbbShap from "../../../../components/UI/SqbbShap/SqbbShap";

const LeadersCup = () => {
    return (
        <>
            <SqbbShap
                height={'50vh'}
                backgroundColor={`linear-gradient(rgba(12, 38, 75, 0.6) 0%, rgba(12, 38, 75, 0.8) 45%, rgba(12, 38, 75, 1) 100%)`}
                backgroundImage={ratte7}
                backgroundAttachment={'fixed'}
                backgroundPosition={'center bottom'}
                backgroundRepeat={'no-repeat'}
                backgroundSize={'cover'}
            >
                <SqbbPageTitle title={"Leaders Cup"} color={"#fff"}></SqbbPageTitle>
            </SqbbShap>

            <section className={ `${classes.compet_leaders_cup} container-fluid row`}>
                <div className={ `${classes.compet_leaders_cup_img_bx } col-sm-12 col-md-12 col-lg-4`}>
                    <img src={ leadersCup } className={classes.leaders_cup_logo} alt="Logo de la Leaders Cup"/>
                </div>
                <div className={ `${classes.compet_leaders_cup_content} col-sm-12 col-md-12 col-lg-8`}>
                    <h3 className={classes.leaders_cup_title}> Leaders Cup</h3>
                    <p>
                        La Leaders Cup (anciennement Disneyland Paris Leaders Cup LNB) est une compétition annuelle de basket-ball révélée le 31 mai 2012 et organisée par la Ligue nationale de basket-ball (LNB) à partir de 2013. Après deux années d’absence en 2021 et 2022, la compétition reprend ses marques en 2023, à l'Aréna Saint-Etienne Métropole (4200 places), de Saint-Chamond.
                    </p>
                    <p>
                        Pour se qualifier, l’équipe doit compter parmi les huit premières du championnat de Betclic Élite à la fin de la phase aller. Le tournoi se déroule lui sur 3 jours sous le format suivant : Quarts de finale, demi-finale et finale se déroulant en une seule rencontre et avec prolongations si nécessaire pour départager les équipes.
                    </p>
                </div>
            </section>

        </>
    );
};

export default LeadersCup;