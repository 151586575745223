import React from 'react';
import betclick from "../../../../img/BetclickElite/BetclicElite_V_Patch.webp";
import classes from "./Betclic.module.css";
import SqbbPageTitle from "../../../../components/UI/SqbbPageTitle/SqbbPageTitle";
import SqbbShap from "../../../../components/UI/SqbbShap/SqbbShap";
import ratte7 from "../../../../img/pierreRatte/pierre_rate4.jpg";

const BetclickElite = () => {
    return (
        <>
            <SqbbShap
                height={'50vh'}
                backgroundColor={`linear-gradient(rgba(12, 38, 75, 0.6) 0%, rgba(12, 38, 75, 0.8) 45%, rgba(12, 38, 75, 1) 100%)`}
                backgroundImage={ratte7}
                backgroundAttachment={'fixed'}
                backgroundPosition={'center bottom'}
                backgroundRepeat={'no-repeat'}
                backgroundSize={'cover'}
            >
                <SqbbPageTitle title={"Betclic élite"} color={"#fff"}></SqbbPageTitle>
            </SqbbShap>
            <section className={`${classes.compet_betclic} container-fluid row`}>
                <div className={`${classes.compet_betclic_img_box} col-sm-12 col-md-12 col-lg-4`}>
                    <img src={ betclick } className={classes.compet_betclic_img} alt="logo Betclic"/>
                </div>
                <div className={`${classes.compet_betclic_text} col-sm-12 col-md-12 col-lg-8`}>
                    <h3 className={classes.compet_title}>Le championnat de France de basket-ball</h3>
                    <p>
                        Nommé Nationale 1 jusqu’en 1993, ProA jusqu’en 2018,
                        Jeep ELITE à compter du 1er mars 2018 et enfin Betclic ELITE depuis depuis la saison 2021-2022,
                        est une compétition annuelle mettant aux prises les dix-huit meilleurs clubs professionnels de basket-ball en France.
                        Le premier titre de champion de France est décerné en 1921.
                        Depuis 1987, c’est la Ligue nationale de basket-ball qui organise la compétition.
                    </p>
                    <p>
                        Sa formule a beaucoup évolué au cours de l’histoire. Actuellement, il se déroule en deux phases :
                        une phase appelée saison régulière où chaque équipe s’affronte en match aller et retour et une phase
                        finale de série éliminatoire appelée playoffs regroupant les huit meilleures équipes de la saison régulière,
                        se jouant au meilleur des trois matchs en quart et demi-finales, puis au meilleur des cinq matchs en finale,
                        depuis 2013 (première saison que ce règlement est mis en place et c’est Nanterre 92 qui remporta
                        alors le titre de Champion de France). Les deux derniers sont relégués en Pro B et sont remplacés par
                        l’équipe classée première de Pro B à l’issue de la saison régulière et par le vainqueur des playoffs de Pro B.
                    </p>
                </div>
            </section>
        </>
    );
};

export default BetclickElite;