import React from 'react';
import classes from "./HomeArticleCard.module.css";
import {NavLink} from "react-router-dom";
import HtmlRenderer from "../../../core/HtmlRenderer";

const HomeArticleCard = (props) => {
    const style = {
        backgroundColor: `linear-gradient(rgba(12, 38, 75, 0.4) 0%, rgba(12, 38, 75, 0.6) 35%, rgba(12, 38, 75, 1) 100%)`,
        backgroundImage: props.bgImg,
        backgroundAttachment: '',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }

    return (
        <div className={`${ classes.sqbb_home_article_card} mt-5 col-md-3`}>
            <div
                className={ classes.sqbb_home_article_card_bx }
                style={{
                    background: `${style.backgroundColor}, url(${style.backgroundImage}) ${style.backgroundRepeat} ${style.backgroundAttachment} ${style.backgroundPosition}`,
                    backgroundSize: style.backgroundSize,
                }}
            >
                <div className={ classes.sqbb_home_article_card_footer } >
                    <h4><HtmlRenderer content={ props.title }></HtmlRenderer></h4>
                    <NavLink to={ props.articleLink } className={ classes.sqbb_home_article_card_read_btn } >
                        Lire l'article
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default HomeArticleCard;