import React, {useEffect, useState} from 'react';
import MatchCard from "../../../components/Matchs/MatchCard";
import {matchsData} from "../../../datas/matchs";
import {teamDatas} from "../../../datas/teams";
import SqbbPageTitle from "../../../components/UI/SqbbPageTitle/SqbbPageTitle";
import SqbbShap from "../../../components/UI/SqbbShap/SqbbShap";
import supporters from "../../../img/supporters/calender.webp";
import classes from "./MatchsSchedules.module.css";

const MatchsSchedules = () => {
    const [ sqbb, setSqbb ] = useState(teamDatas[0])
    const computeMatchs = () => {
        return matchsData.map(match => {
            const adversaire = teamDatas.find(team => team.id === match.opponentId);
            return {
                id: match.id,
                date: match.date,
                hours: null,
                month: match.month,
                competition: match.competition,
                resume: match.resume,
                domicileTeamName: match.isHome ? sqbb.name : adversaire.name,
                domicileTeamLogo: match.isHome ? sqbb.logo : adversaire.logo,
                externTeamName: match.isHome ? adversaire.name : sqbb.name,
                externTeamLogo: match.isHome ? adversaire.logo : sqbb.logo,
                place: match.isHome ? sqbb.salle : adversaire.salle,
                isHome: false,
                opponentId: match.opponentId,
                homeBasedTeamScore: match.homeBasedTeamScore,
                outsideTeamScore: match.outsideTeamScore
            }
        });
    }
    const [ matchs, setMatchs] = useState(computeMatchs);
    const [ matchToDisplay, setMatchToDisplay] = useState(matchs);
    const [ selectedMatchs, setSelectedMatchs ] = useState('all');

    const monthSelectHandle = (e) => {
        const selectedMonth = e.target.value;
        setSelectedMatchs(prevState => selectedMonth);
        if(selectedMonth === "all"){
            setMatchToDisplay(matchs);
        } else {
            const newMatchToDisplay = matchs.filter(match => match.month === selectedMonth);
            setMatchToDisplay(newMatchToDisplay);
        }
    }

    const displayMonthMatch = (month) => {
        if(selectedMatchs === month || selectedMatchs === "all"){
            return(
                <>
                    <h2 className={classes.matchs_month_title}>{ month.replaceAll("é","e") }</h2>
                    {matchToDisplay.map((match, index) => {
                        if (match.month === month){
                            return <MatchCard { ...match } key={index}/>
                        }
                    })}
                </>
            )
        }
    }

    const style = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    }

    return (
        <>
            <SqbbShap
                height={'50vh'}
                backgroundColor={`linear-gradient(rgba(12, 38, 75, 0.1) 0%, rgba(12, 38, 75, 0.6) 45%, rgba(12, 38, 75, 1) 100%)`}
                backgroundImage={supporters}
                backgroundAttachment={'fixed'}
                backgroundPosition={'center center'}
                backgroundRepeat={'no-repeat'}
                backgroundSize={'cover'}
            >
                <SqbbPageTitle title={"Calendrier des matchs"} color={"#fff"}></SqbbPageTitle>
            </SqbbShap>

            <section className={"match_select_bx"}>
                <div className={"select_match_month"}>
                    <label htmlFor="month" className={"matchs_select_label"}>Sélectionner un mois</label>
                    <select id="month"  className={"matchs_select"} name="month" onChange={(e) => monthSelectHandle(e)}>
                        <option className={"matchs_select_option"} value="all">Tous les matchs</option>
                        <option className={"matchs_select_option"} value="Septembre">Septembre</option>
                        <option className={"matchs_select_option"} value="Octobre">Octobre</option>
                        <option className={"matchs_select_option"} value="Novembre">Novembre</option>
                        <option className={"matchs_select_option"} value="Décembre">Décembre</option>
                        <option className={"matchs_select_option"} value="Janvier">Janvier</option>
                        <option className={"matchs_select_option"} value="Février">Février</option>
                        <option className={"matchs_select_option"} value="Mars">Mars</option>
                        <option className={"matchs_select_option"} value="Avril">Avril</option>
                    </select>
                </div>
            </section>

            <section id={"matchs"} style={ style }>
                { displayMonthMatch ("Septembre")}
                { displayMonthMatch ("Octobre")}
                { displayMonthMatch ("Novembre")}
                { displayMonthMatch ("Décembre")}
                { displayMonthMatch ("Janvier")}
                { displayMonthMatch ("Février")}
                { displayMonthMatch ("Mars")}
                { displayMonthMatch ("Avril")}
                { displayMonthMatch ("Mai")}
            </section>
        </>
    );
};

export default MatchsSchedules;