import React from 'react';
import ratte2 from "../../../img/pierreRatte/pierre_rate2.jpg";
import ratte3 from "../../../img/pierreRatte/pierre_rate3.jpg";
import classes from "./Parquet.module.css";
import ratte5 from '../../../img/pierreRatte/faire_bruit.webp';
import ratte6 from "../../../img/pierreRatte/ratte6.webp";
import SqbbPageTitle from "../../../components/UI/SqbbPageTitle/SqbbPageTitle";
import SqbbShap from "../../../components/UI/SqbbShap/SqbbShap";

const Parquet = () => {
    return (
        <>
            <SqbbShap
                height={'50vh'}
                backgroundColor={`linear-gradient(rgba(12, 38, 75, 0.6) 0%, rgba(12, 38, 75, 0.8) 45%, rgba(12, 38, 75, 1) 100%)`}
                backgroundImage={ratte2}
                backgroundAttachment={'fixed'}
                backgroundPosition={'center center'}
                backgroundRepeat={'no-repeat'}
                backgroundSize={'cover'}
            >
                <SqbbPageTitle title={"Pierre Ratte"} color={"#fff"}></SqbbPageTitle>
            </SqbbShap>
            <section className={ classes.origin }>
                <div className={ classes.origin_container } data-aos="zoom-out">
                    <div className={ classes.origin_text_content }>
                        <h2 className={ classes.original_h2 }>L'origine</h2>
                        <h6 className={ classes.original_h6 }>Du palais des sports</h6>
                        <p>
                            Médecin du sport et membre du Conseil municipal de Saint-Quentin dans les années 60, Pierre Ratte (1925-1995) donne son nom à l’enceinte sportive dans laquelle évolue le Saint-Quentin BasketBall.
                        </p>
                        <p>
                            À l’origine du projet d’une salle omnisport en 1971, l’homme a contribué au rayonnement de la ville sur le plan sportif. Il est d’ailleurs toujours possible d’apercevoir sa statue offerte par la mairie dans le hall du Palais des sports.
                        </p>
                    </div>
                    <div className={ classes.origin_img_box }>
                        <img src={ ratte2 }  className={ classes.origin_img} alt={"photo devant pierre ratte"}/>
                    </div>
                </div>
            </section>

            <section className={ classes.in_the } >
                <div className={ classes.in_the_container } style={{ background: `linear-gradient(rgba(12, 38, 75, 0.2) 0%, rgba(12, 38, 75, 0.5) 45%, rgba(12, 38, 75, 1) 100%), url(${ratte3}) center center #000`}}>
                    <h3 className={ classes.in_the_h3 }>EN PLEIN COEUR </h3>
                    <h6 className={ classes.original_h6 }>DE SAINT-QUENTIN </h6>
                    <p>
                        Avec une capacité maximale de 3500 personnes pour 3000 places assises,
                        la salle que l’on appelle désormais plus simplement « Pierre Ratte » trouve refuge au bout de nos Champs Élysées,
                        en plein de coeur de Saint-Quentin.
                    </p>
                    <p>
                        Chaque soir de matchs, ce sont plusieurs milliers de supporters aux couleurs du SQBB qui arpentent
                        l’Avenue de Remicourt pour aller encourager leur équipe préférée.
                    </p>
                </div>
            </section>

            <section className={ classes.noises }>
                <div className={ classes.noises_container } >
                    <div className={ classes.noises_text_content }>
                        <h3 className={ classes.noises_text_content_h3 }> FAIRE DU BRUIT !</h3>
                        <p>
                            À l’intérieur, le Palais des sports est connu pour une chose : faire du bruit ! Et pour cela,
                            nos fidèles groupes de supporters font le travail match après match.
                        </p>
                        <p>
                            Pendant que les stimulants orchestrent le tempo des encouragements depuis La Tribune B, White Devils, Globe Trotters
                            et Renards blanc s’occupent d’encourager nos joueurs sous les deux paniers du terrain.
                        </p>
                        <h6 className={ classes.noises_text_content_h6 }>Prêt à entrer en Fusion</h6>
                        <p>
                            Depuis toutes ces années, le Palais des sports Pierre Ratte a vu le Saint-Quentin BasketBall passer par toutes les émotions, entre péripéties
                            et grandes victoires, entre larmes de tristesse et larmes de joie.
                        </p>
                        <p>
                            Aujourd’hui, après une pandémie ayant réduit le Palais des sports au silence, Pierre Ratte est de nouveau prêt à faire du bruit et à entrer en fusion.
                        </p>
                    </div>
                    <div className={ classes.noises_img_bx }>
                        <img src={ ratte5 } className={ classes.noises_img } alt="image Palais des sport Pierre Ratte"/>
                    </div>
                </div>
            </section>

            <section className={classes.ambiance} style={{backgroundImage: `linear-gradient(rgba(12, 38, 75, 0.2) 0%, rgba(12, 38, 75, 0.5) 45%, rgba(12, 38, 75, 1) 100%), url(${ratte6})`, backgroundSize: "cover"}}>
                <div className={classes.ambiance_container}>
                    <h2 className={classes.ambiance_h2}> Une ambiance <br/> Volcanique</h2>
                    <p>
                        Tu n’as pas encore vécu l’engouement et l’ambiance d’un match de basket au Palais des sports Pierre Ratte ?
                        Réserve une place dès maintenant pour vivre une ambiance volcanique.
                    </p>
                    <p>
                        Adresse :
                        Champs Élysées - Av. de Remicourt 02100 Saint-Quentin
                    </p>
                </div>
            </section>
        </>
    );
};

export default Parquet;