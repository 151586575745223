import React from 'react';
import HtmlRenderer from "../../../core/HtmlRenderer";
import {NavLink} from "react-router-dom";

export default function ArticleCore(props) {

    const {mainImg, title, author, date, renderedContent, termCategory, termTag} = props;

    return (
        <>
             <article className="blog-details" style={{background: "#fff", fontFamily: "NoirProLight, sans-serif"}}>

                <div className="post-img">
                    <img src={ mainImg } alt="" className="img-fluid"/>
                </div>

                <h2
                    className="title"
                    style={{fontFamily: "LTEnergy, sans-serif", textTransform: "uppercase", fontSize: "3rem"}}
                >
                    { <HtmlRenderer content={ title }></HtmlRenderer> }
                </h2>

                <div className="meta-top">
                    <ul>
                        <li className="d-flex align-items-center"><i className="bi bi-person"></i> { author } </li>
                        <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <time dateTime="2023-08-15">{ new Date(date).toLocaleDateString('fr-FR', {year: 'numeric', month: 'long', day: 'numeric'}) }</time></li>
                    </ul>
                </div>

                <div className="content" style={{color: "#000"}}>
                    <HtmlRenderer content={ renderedContent }></HtmlRenderer>
                </div>

                <div className="meta-bottom">
                    <i className="bi bi-folder"></i>
                    <ul className="cats">
                        {
                            termCategory &&
                                termCategory.map(category => {
                                    return (
                                        <li key={category.id}>
                                            <NavLink to={`/actualites/category/${category.id}`}>{category.slug}</NavLink>
                                        </li>
                                    )
                                })
                        }
                        {
                            !termCategory &&
                                <li><p>Pas de catégories</p></li>
                        }
                    </ul>

                    <i className="bi bi-tags"></i>
                    <ul className="tags">
                        {
                            termTag &&
                            termTag.map(tag => {
                                    return (
                                        <li key={tag.id}>
                                            <NavLink to={`/blog/actualites/tag/${tag.id}`}>{tag.slug}</NavLink>
                                        </li>
                                    )
                                })
                            }
                        {
                            !termTag &&
                                <li><p>Pas de tags</p></li>
                        }
                    </ul>
                </div>

            </article>
        </>
    );
};