import React from 'react';
import classes from "./PartnerCard.module.css";
const PartnerCard = (props) => {
    return (
        <div>
            <div className={ classes.partners__card }>
                <div className={ classes.partners__imgBx }>
                    <a href={props.partner.link} target='_blank'>
                        {
                            props.partner.img
                                ? <img src={ props.partner.img } alt="design"/>
                                : <div className={classes.partner__without_img }>{ props.partner.name }</div>
                        }

                    </a>
                </div>
            </div>
        </div>
    );
};

export default PartnerCard;