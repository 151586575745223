export const matchsData = [
  {
    id: 1,
    date: "16",
    hours: "",
    month: "Septembre",
    year: "2023",
    fullDate: "9/16/2023",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 2,
    homeBasedTeamScore: 69,
    outsideTeamScore: 73,
  },
  {
    id: 2,
    date: "23",
    hours: "",
    month: "Septembre",
    year: "2023",
    fullDate: "09/23/2023",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 3,
    homeBasedTeamScore: 65,
    outsideTeamScore: 81,
  },
  {
    id: 3,
    date: "26",
    hours: "",
    month: "Septembre",
    year: "2023",
    fullDate: "09/26/2023",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 4,
    homeBasedTeamScore: 77,
    outsideTeamScore: 75,
  },
  {
    id: 4,
    date: "03",
    hours: "",
    month: "Octobre",
    year: "2023",
    fullDate: "10/03/2023",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 6,
    homeBasedTeamScore: 91,
    outsideTeamScore: 71,
  },
  {
    id: 5,
    date: "07",
    hours: "",
    month: "Octobre",
    year: "2023",
    fullDate: "10/07/2023",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 7,
    homeBasedTeamScore: 75,
    outsideTeamScore: 70,
  },
  {
    id: 6,
    date: "14",
    hours: "",
    month: "Octobre",
    year: "2023",
    fullDate: "10/14/2023",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 8,
    homeBasedTeamScore: 69,
    outsideTeamScore: 78,
  },
  {
    id: 7,
    date: "17",
    hours: "",
    month: "Octobre",
    year: "2023",
    fullDate: "10/17/2023",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 8,
    homeBasedTeamScore: 78,
    outsideTeamScore: 76,
  },
  {
    id: 8,
    date: "21",
    hours: "",
    month: "Octobre",
    year: "2023",
    fullDate: "10/21/2023",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 9,
    homeBasedTeamScore: 71,
    outsideTeamScore: 78,
  },
  {
    id: 9,
    date: "29",
    hours: "",
    month: "Octobre",
    year: "2023",
    fullDate: "10/29/2023",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 10,
    homeBasedTeamScore: 75,
    outsideTeamScore: 83,
  },
  {
    id: 10,
    date: "04",
    hours: "",
    month: "Novembre",
    year: "2023",
    fullDate: "11/04/2023",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 11,
    homeBasedTeamScore: 80,
    outsideTeamScore: 75,
  },
  {
    id: 11,
    date: "07",
    hours: "",
    month: "Novembre",
    year: "2023",
    fullDate: "11/07/2023",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 5,
    homeBasedTeamScore: 78,
    outsideTeamScore: 66,
  },
  {
    id: 12,
    date: "11",
    hours: "",
    month: "Novembre",
    year: "2023",
    fullDate: "11/11/2023",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 12,
    homeBasedTeamScore: 92,
    outsideTeamScore: 61,
  },
  {
    id: 13,
    date: "18",
    hours: "18h30",
    month: "Novembre",
    year: "2023",
    fullDate: "11/18/2023",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 13,
    homeBasedTeamScore: 77,
    outsideTeamScore: 69,
  },
  {
    id: 14,
    date: "21",
    hours: "20h00",
    month: "Novembre",
    year: "2023",
    fullDate: "11/21/2023",
    competition: "Coupe de France",
    resume: "",
    isHome: true,
    opponentId: 19,
    homeBasedTeamScore: 92,
    outsideTeamScore: 49,
  },
  {
    id: 15,
    date: "25",
    hours: "18h30",
    month: "Novembre",
    year: "2023",
    fullDate: "11/25/2023",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 14,
    homeBasedTeamScore: 75,
    outsideTeamScore: 60,
  },
  {
    id: 16,
    date: "03",
    hours: "14h00",
    month: "Décembre",
    year: "2023",
    fullDate: "12/03/2023",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 15,
    homeBasedTeamScore: 60,
    outsideTeamScore: 75,
  },
  {
    id: 17,
    date: "09",
    hours: "",
    month: "Décembre",
    year: "2023",
    fullDate: "12/09/2023",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 16,
    homeBasedTeamScore: 57,
    outsideTeamScore: 64,
  },
  {
    id: 18,
    date: "16",
    hours: "",
    month: "Décembre",
    year: "2023",
    fullDate: "12/16/2023",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 17,
    homeBasedTeamScore: 76,
    outsideTeamScore: 77,
  },
  {
    id: 19,
    date: "23",
    hours: "",
    month: "Décembre",
    year: "2023",
    fullDate: "12/23/2023",
    competition: "Betclic Elite",
    resume: "",
    isHome: true,
    opponentId: 18,
    homeBasedTeamScore: 88,
    outsideTeamScore: 67,
  },
  {
    id: 20,
    date: "28",
    hours: "",
    month: "Décembre",
    year: "2023",
    fullDate: "12/28/2023",
    competition: "Betclic Elite",
    resume: "",
    isHome: false,
    opponentId: 12,
    homeBasedTeamScore: 86,
    outsideTeamScore: 71,
  },
  {
    id: 21,
    date: "13",
    hours: "",
    month: "Janvier",
    year: "2024",
    fullDate: "01/13/2024",
    competition: "Betclic Elite",
    resume: "https://www.lnb.fr/elite/game-center-resume/?id=26527",
    isHome: true,
    opponentId: 2,
    homeBasedTeamScore: 68,
    outsideTeamScore: 82,
  },
  {
    id: 22,
    date: "20",
    hours: "",
    month: "Janvier",
    year: "2024",
    fullDate: "01/20/2024",
    competition: "Betclic Elite",
    resume: "https://www.lnb.fr/elite/game-center-resume/?id=26535",
    isHome: false,
    opponentId: 16,
    homeBasedTeamScore: 90,
    outsideTeamScore: 96,
  },
  {
    id: 23,
    date: "27",
    hours: "",
    month: "Janvier",
    year: "2024",
    fullDate: "01/27/2024",
    competition: "Betclic Elite",
    resume: "https://www.lnb.fr/elite/game-center-resume/?id=26543",
    isHome: false,
    opponentId: 9,
    homeBasedTeamScore: 69,
    outsideTeamScore: 77,
  },
  {
    id: 24,
    date: "03",
    hours: "",
    month: "Février",
    year: "2024",
    fullDate: "02/03/2024",
    competition: "Betclic Elite",
    resume: "https://www.lnb.fr/elite/game-center-resume/?id=26554",
    isHome: true,
    opponentId: 17,
    homeBasedTeamScore: 81,
    outsideTeamScore: 64,
  },
  {
    id: 25,
    date: "10",
    hours: "",
    month: "Février",
    year: "2024",
    fullDate: "02/10/2024",
    competition: "Betclic Elite",
    resume: "https://www.lnb.fr/elite/game-center-resume/?id=26561",
    isHome: false,
    opponentId: 3,
    homeBasedTeamScore: 87,
    outsideTeamScore: 65,
  },
  {
    id: 26,
    date: "02",
    hours: "",
    month: "Mars",
    year: "2024",
    fullDate: "03/02/2024",
    competition: "Betclic Elite",
    resume: "https://www.lnb.fr/elite/game-center-resume/?id=26572",
    isHome: true,
    opponentId: 8,
    homeBasedTeamScore: 68,
    outsideTeamScore: 76,
  },
  {
    id: 27,
    date: "09",
    hours: "",
    month: "Mars",
    year: "2024",
    fullDate: "03/09/2024",
    competition: "Betclic Elite",
    resume: "https://www.lnb.fr/elite/game-center-resume/?id=26581",
    isHome: true,
    opponentId: 15,
    homeBasedTeamScore: 75,
    outsideTeamScore: 64,
  },
  {
    id: 28,
    date: "23",
    hours: "",
    month: "Mars",
    year: "2024",
    fullDate: "03/23/2024",
    competition: "Betclic Elite",
    resume: "https://www.lnb.fr/elite/game-center-resume/?id=26583",
    isHome: false,
    opponentId: 14,
    homeBasedTeamScore: 73,
    outsideTeamScore: 69,
  },
  {
    id: 29,
    date: "26",
    hours: "",
    month: "Mars",
    year: "2024",
    fullDate: "03/26/2024",
    competition: "Betclic Elite",
    resume: "https://www.lnb.fr/elite/game-center-resume/?id=26612",
    isHome: false,
    opponentId: 18,
    homeBasedTeamScore: 85,
    outsideTeamScore: 76,
  },
  {
    id: 30,
    date: "30",
    hours: "",
    month: "Mars",
    year: "2024",
    fullDate: "03/30/2024",
    competition: "Betclic Elite",
    resume: "https://www.lnb.fr/elite/game-center-resume/?id=26599",
    isHome: true,
    opponentId: 4,
    homeBasedTeamScore: null,
    outsideTeamScore: null,
  },

  {
    id: 31,
    date: "06",
    hours: "",
    month: "Avril",
    year: "2024",
    fullDate: "04/06/2024",
    competition: "Betclic Elite",
    resume: "https://www.lnb.fr/elite/game-center-resume/?id=26605",
    isHome: false,
    opponentId: 10,
    homeBasedTeamScore: null,
    outsideTeamScore: null,
  },
  {
    id: 32,
    date: "20",
    hours: "",
    month: "Avril",
    year: "2024",
    fullDate: "04/20/2024",
    competition: "Betclic Elite",
    resume: "https://www.lnb.fr/elite/game-center-resume/?id=26626",
    isHome: true,
    opponentId: 13,
    homeBasedTeamScore: null,
    outsideTeamScore: null,
  },
  {
    id: 33,
    date: "23",
    hours: "",
    month: "Avril",
    year: "2024",
    fullDate: "04/23/2024",
    competition: "Betclic Elite",
    resume: "https://www.lnb.fr/elite/game-center-resume/?id=26635",
    isHome: true,
    opponentId: 11,
    homeBasedTeamScore: null,
    outsideTeamScore: null,
  },
  {
    id: 34,
    date: "30",
    hours: "",
    month: "Avril",
    year: "2024",
    fullDate: "04/30/2024",
    competition: "Betclic Elite",
    resume: "https://www.lnb.fr/elite/game-center-resume/?id=26640",
    isHome: false,
    opponentId: 6,
    homeBasedTeamScore: null,
    outsideTeamScore: null,
  },
  {
    id: 35,
    date: "04",
    hours: "",
    month: "Mai",
    year: "2024",
    fullDate: "05/04/2024",
    competition: "Betclic Elite",
    resume: "https://www.lnb.fr/elite/game-center-resume/?id=26653",
    isHome: false,
    opponentId: 7,
    homeBasedTeamScore: null,
    outsideTeamScore: null,
  },
  {
    id: 36,
    date: "11",
    hours: "",
    month: "Mai",
    year: "2023",
    fullDate: "05/11/2024",
    competition: "Betclic Elite",
    resume: "https://www.lnb.fr/elite/game-center-resume/?id=26662",
    isHome: true,
    opponentId: 5,
    homeBasedTeamScore: null,
    outsideTeamScore: null,
  },
];
