import React from 'react';
import Shape from "../components/UI/Shap/Shape";
import './Contact.css';
import bg1 from "../img/slider2.webp"
import logo from "../img/logo.png";
import SqbbPageTitle from "../components/UI/SqbbPageTitle/SqbbPageTitle";
import SqbbShap from "../components/UI/SqbbShap/SqbbShap";

const Contact = () => {
    return (
        <>
            <SqbbShap
                height={'50vh'}
                backgroundColor={`linear-gradient(rgba(12, 38, 75, 0.4) 0%, rgba(12, 38, 75, 0.6) 35%, rgba(12, 38, 75, 1) 100%)`}
                backgroundImage={bg1}
                backgroundAttachment={'fixed'}
                backgroundPosition={'center bottom'}
                backgroundRepeat={'no-repeat'}
                backgroundSize={'cover'}
            >
                <SqbbPageTitle title={"Contactez-nous"}></SqbbPageTitle>
            </SqbbShap>

            <section id="about" className="sqbb_contact">

                <div className="container" data-aos="fade-up" data-aos-delay="100">

                    <div className="row align-items-xl-center gy-5" style={{display: "flex", flexDirection: "column"}}>

                        <div className="col-xl-5 content" style={{marginBottom: "50px"}}>
                            <div className={'sqbb_logo'}>
                                <img src={ logo } alt="Logo du club de Basket de Saint-Quentin"/>
                            </div>
                            <p>Pour contacter nos collaborateurs, vous pouvez utiliser un des moyens suivants :</p>

                        </div>

                        <div className="col-xl-7">
                            <div className="row gy-4 icon-boxes">

                                <div className="col-md-6" data-aos="fade-up" data-aos-delay="200">
                                    <div className="icon-box">
                                        <i className="ri-map-pin-line"></i>
                                        <h3>Adresse</h3>
                                        <p>Salle : Palais des sports Pierre Rate; (3 800 places)</p>
                                        <p>Siége : 6, place Lafayette; 02100 Saint-Quentin</p>
                                    </div>
                                </div>

                                <div className="col-md-6" data-aos="fade-up" data-aos-delay="300">
                                    <div className="icon-box">
                                        <i className="ri-time-line"></i>
                                        <h3>Horaire</h3>
                                        <p>Votre club est ouvert le lundi/mardi/jeudi/Vendredi, de 9h à 12h et de 14h à 18h et le mercredi de 9h à 11h</p>
                                    </div>
                                </div>
                                <div className="col-md-6" data-aos="fade-up" data-aos-delay="400">
                                    <div className="icon-box">
                                        <i className="ri-mail-line"></i>
                                        <h3>Mail</h3>
                                        <a href={"mailto:contact@sqbb.fr"}>contact@sqbb.fr</a>
                                    </div>
                                </div>

                                <div className="col-md-6" data-aos="fade-up" data-aos-delay="500">
                                    <div className="icon-box">
                                        <i className="ri-phone-line"></i>
                                        <h3>Téléphone</h3>
                                        <p>03 23 68 63 50</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </section>

            <section id="contact" className="sqbb_map">
                <div className="map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2572.528842069231!2d3.284285377149662!3d49.85130987148395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e81887cecc446b%3A0xe189f0e81690ccbb!2s6%20Pl.%20la%20Fayette%2C%2002100%20Saint-Quentin!5e0!3m2!1sfr!2sfr!4v1687985539511!5m2!1sfr!2sfr"
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </section>
        </>

    );
};

export default Contact;