import React from 'react';
import classes from "./PlayerResume.module.css";

const PlayerResume = ( props ) => {
    return (
        <div className="container">

            <div className="section-title">
                <h2>Carrière</h2>
                <p>En CLub</p>
            </div>

            <div className="row">
                <div className="col-lg-6">
                    <h3 className={ classes.resume_title }>
                        Carrière Professionnelle
                    </h3>
                    {props.player.carrier.pro.map((step, index) => {
                        return (
                            <>

                                <div className={ classes.resume_item } key={index}>
                                    <h4 className={ classes.team_name }> { step.club }</h4>
                                    <h5 className={ classes.date_range }> { step.comeIn} - { step.ending }</h5>
                                    <p><em>{ step.leagueName }</em></p>
                                </div>
                            </>
                        )
                    })}
                </div>

                <div className="col-lg-6">
                    <h3 className={ classes.resume_title }>Carrière universitaire ou amateur</h3>
                    {
                        props.player.carrier.amateur.map((step, index) => {
                            return (
                                <>
                                    <div className={ classes.resume_item } key={index}>
                                        <h4 className={ classes.team_name }> { step.club }</h4>
                                        <h5 className={ classes.date_range }> { step.comeIn} - { step.ending }</h5>
                                    </div>
                                </>
                            )
                        })
                    }

                    <h3 className={classes.resume_title} style={{ marginTop: "2em" }}>Palmarès</h3>

                    {
                        props.player.carrier.palmares.map((step, index) => {
                            return(
                                <div className={ classes.resume_item } key={index}>
                                    <h4 className={ classes.team_name }>{ step.championchipTitle }</h4>
                                    <h5 className={ classes.date_range }>{ step.year }</h5>
                                    <p><em>{ step.championship }</em></p>
                                </div>
                            )
                        })
                    }

                </div>
            </div>

        </div>
    );
};

export default PlayerResume;