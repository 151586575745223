import React, {useState, useEffect, useCallback} from 'react';
import HandleArticleCoverImg from "../../../core/HandleArticleCoverImg";
import {NavLink} from "react-router-dom";
import HtmlRenderer from "../../../core/HtmlRenderer";

const SideBarRecentPost = () => {
    const [latestPost, setLatestPost] = useState([])

    const getLatestPost = useCallback(async () => {
        try {
            const response = await fetch('https://back-office-sqbb.fr/wp-json/wp/v2/posts?per_page=4&_embed=true&_sort=desc');
            const jsonResponse = await response.json();

            setLatestPost(jsonResponse);
        }catch (err) {
            console.error(err)
        }
    }, [])

    useEffect(() => {
        getLatestPost();
    }, [getLatestPost])

    return (
        <>
            <div className="sidebar-item recent-posts">
                <h3 className="sidebar-title">Articles récents</h3>
                <div className="mt-3">
                    {
                            latestPost && latestPost.map(recent => {
                            return(
                                <div className="post-item mt-3" style={{display: "flex", alignItems: "center"}} key={recent.id}>
                                    <div className={"thumbnail"} style={{height: "100px", width: "100px"}}>
                                        <img src={ HandleArticleCoverImg(recent._embedded["wp:featuredmedia"], "attachment", "thumbnail") } style={{ height: "100%", objectFit: "cover", overflow: "hidden"}} alt=""/>
                                    </div>
                                    <div>
                                        <h4>
                                            <NavLink to={`/blog/actualites/article/${ recent.id }`} target={"_blank"}>
                                                <HtmlRenderer content={ recent.title.rendered }></HtmlRenderer>
                                            </NavLink>
                                        </h4>
                                        <time dateTime={new Date(recent.date).toLocaleString()}>{ new Date(recent.date).toLocaleDateString('fr-FR', {day: 'numeric', month: 'long', year: 'numeric'}) }</time>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    );
};

export default SideBarRecentPost;